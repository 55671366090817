import { Fragment, JSX, memo, useCallback, useState } from "react";

import { ClientHints } from "./BrowserInformation/ClientHints";
import { Viewport } from "./BrowserInformation/Viewport";
import { RequestHeaders } from "./ClientInformation/RequestHeaders";
import { UserAddress } from "./ClientInformation/UserAddress";
import { BaseConversion } from "./Conversion/BaseConversion";
import { TimeConversion } from "./Conversion/TimeConversion";
import { YamlConversion } from "./Conversion/YamlConversion";
import { JsonData } from "./Data/JsonData";
import { JwtInspector } from "./Data/JwtInspector";
import { UrlParser } from "./Data/UrlParser";
import { XmlData } from "./Data/XmlData";
import { Base64Encoding } from "./Encoding/Base64Encoding";
import { HtmlEncoding } from "./Encoding/HtmlEncoding";
import { QrEncoding } from "./Encoding/QrEncoding";
import { StringEncoding } from "./Encoding/StringEncoding";
import { UrlEncoding } from "./Encoding/UrlEncoding";
import { Md5Hashing } from "./Hashing/Md5Hashing";
import { Sha1Hashing } from "./Hashing/Sha1Hashing";
import { Sha256Hashing } from "./Hashing/Sha256Hashing";
import { SlowLoadingResourceSimulator, SlowLoadingScriptSimulator } from "./WebTools/Simulators";

// Information

type MenuOption = [id: string, title: string, component: () => JSX.Element];
type MenuOptionGroup = [title: string, options: MenuOption[]];
type Menu = MenuOptionGroup[];

const menu: Menu = [
	["Data", [
		["json", "JSON", () => <JsonData />],
		["jwt", "JWT", () => <JwtInspector />],
		["urlparser", "URL parser", () => <UrlParser />],
		["xml", "XML", () => <XmlData />]
	]],
	["Encoding", [
		["base64", "Base64", () => <Base64Encoding />],
		["html", "HTML", () => <HtmlEncoding />],
		["qr", "QR code", () => <QrEncoding />],
		["string", "String", () => <StringEncoding />],
		["url", "URL", () => <UrlEncoding />]
	]],
	["Hashing", [
		["md5", "MD5", () => <Md5Hashing />],
		["sha1", "SHA-1", () => <Sha1Hashing />],
		["sha256", "SHA-256", () => <Sha256Hashing />]
	]],
	["Conversion", [
		["base", "Number bases", () => <BaseConversion />],
		["time", "Time formats", () => <TimeConversion />],
		["yaml", "YAML to JSON", () => <YamlConversion />]
	]],
	["Browser information", [
		["viewport", "Viewport", () => <Viewport />],
		["clienthints", "User-Agent Client Hints", () => <ClientHints />]
	]],
	["Client information", [
		["address", "IP address", () => <UserAddress />],
		["headers", "Request headers", () => <RequestHeaders />]
	]],
	["Web tools", [
		["slowres", "Slow resource sim", () => <SlowLoadingResourceSimulator />],
		["slowscript", "Slow script sim", () => <SlowLoadingScriptSimulator />]
	]]
];

function getMenuName(tool: string) {
	if (tool === "all") {
		return "All tools";
	}

	if (tool === "welcome") {
		return "Welcome";
	}

	for (const group of menu) {
		for (const option of group[1]) {
			if (option[0] === tool) {
				return option[1];
			}
		}
	}

	return "";
}

function isMenuOption(tool: string) {
	if (tool === "welcome" || tool === "all") {
		return true;
	}

	for (const group of menu) {
		for (const option of group[1]) {
			if (option[0] === tool) {
				return true;
			}
		}
	}

	return false;
}

const initialDocumentTitle = document.title;

interface IToolHistoryState {
	tool: string;
}

function isToolHistoryState(state: unknown): state is IToolHistoryState {
	if (state == null || typeof state !== "object") {
		return false;
	}

	const maybeState = state as Partial<IToolHistoryState>;

	return typeof maybeState.tool === "string";
}

function pushHistory(state: IToolHistoryState) {
	const url = new URL(document.location.href);
	url.hash = state.tool;
	history.pushState(state, "", url);
}

function replaceHistory(state: IToolHistoryState) {
	history.replaceState(state, "", document.location.href);
}

function updateTitle(tool: string) {
	const title = getMenuName(tool);
	if (title) {
		document.title = `${title} - ${initialDocumentTitle}`;
	}
}

function useTool(): [tool: string, selectTool: (selectedTool: string) => void] {
	const [tool, setTool] = useState(() => {
		const options: string[] = [
			document.location.hash.substring(1)
		];

		if (isToolHistoryState(history.state)) {
			options.push(history.state.tool);
		}

		const initialTool = options.find(o => isMenuOption(o)) ?? "welcome";
		replaceHistory({ tool: initialTool });
		updateTitle(initialTool);

		return initialTool;
	});

	const selectTool = useCallback((selectedTool: string) => {
		setTool(selectedTool);
		pushHistory({ tool: selectedTool });
		updateTitle(selectedTool);
	}, []);

	window.addEventListener("popstate", e => {
		if (isToolHistoryState(e.state)) {
			setTool(e.state.tool);
			updateTitle(e.state.tool);
		}
	});

	return [tool, selectTool];
}

function Option(props: { option: MenuOption }) {
	const [id, title] = props.option;

	return <option value={id}>{title}</option>;
}

function OptionGroup(props: { group: MenuOptionGroup }) {
	const [title, options] = props.group;

	return <optgroup label={title}>{options.map(option => <Option key={option[0]} option={option} />)}</optgroup>;
}

const MobileMenu = memo(function MobileMenu(props: { tool: string; onSelectTool: (tool: string) => void }) {
	return (
		<nav className="d-md-none">
			<select className="form-select" onChange={e => props.onSelectTool(e.target.value)} value={props.tool}>
				<option value="welcome">Welcome</option>
				<option value="all">Show all</option>
				{menu.map(group => <OptionGroup key={group[0]} group={group} />)}
			</select>
		</nav>
	);
});

function MenuLink(props: { id: string; className?: string; name: string; active: boolean; onSelectTool: (tool: string) => void }) {
	function onClick(e: React.MouseEvent<HTMLAnchorElement>) {
		if (e.altKey || e.ctrlKey || e.shiftKey) {
			return;
		}

		props.onSelectTool(props.id);
		e.preventDefault();
	}

	return <a href={`#${props.id}`} className={(props.className ?? "") + (props.active ? " active" : "") + ` t-${props.id}-link`} onClick={onClick}>{props.name}</a>;
}

function LinkGroup(props: { currentTool: string; group: MenuOptionGroup; onSelectTool: (tool: string) => void }) {
	const [title, options] = props.group;

	return (
		<div className="mb-4">
			<label className="form-label">{title}</label>
			<div className="list-group">
				{options.map(option => <MenuLink key={option[0]} id={option[0]} className="list-group-item" name={option[1]} active={option[0] === props.currentTool} onSelectTool={props.onSelectTool} />)}
			</div>
		</div>
	);
}

const DesktopMenu = memo(function DesktopMenu(props: { currentTool: string; onSelectTool: (tool: string) => void }) {
	return (
		<nav className="tools-nav d-none d-md-block">
			{menu.map(group => <LinkGroup key={group[0]} currentTool={props.currentTool} group={group} onSelectTool={props.onSelectTool} />)}
			<hr />
			<div className="list-group">
				<MenuLink id="welcome" className="list-group-item" name="Welcome" active={props.currentTool === "welcome"} onSelectTool={props.onSelectTool} />
				<MenuLink id="all" className="list-group-item" name="Show all" active={props.currentTool === "all"} onSelectTool={props.onSelectTool} />
			</div>
		</nav>
	);
});

const MemoizedTool = memo(function MemoizedTool(props: { render: () => JSX.Element }) {
	return props.render();
});

export function ToolsMenu() {
	const [tool, selectTool] = useTool();

	return (
		<div className="content-layout flex-column flex-md-row">
			<div className="flex-shrink-0">
				<MobileMenu tool={tool} onSelectTool={selectTool} />
				<DesktopMenu currentTool={tool} onSelectTool={selectTool} />
			</div>
			<div className="flex-grow-1" style={{ minWidth: 0 }}>
				<div className="tools section-layout">
					<article className={tool === "welcome" ? "d-block" : "d-none"}>
						<h2>Welcome</h2>
						<p>Pick a tool, any tool.</p>
					</article>
					{menu.map(group => group[1].map(option => {
						const show = tool === option[0] || tool === "all";

						return (
							<Fragment key={option[0]}>
								<div className={show ? "d-block" : "d-none"}>
									<MemoizedTool render={option[2]} />
								</div>
								{tool === "all" ? <hr /> : null}
							</Fragment>
						);
					}))}
				</div>
			</div>
		</div>
	);
}
