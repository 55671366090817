import base64 from "crypto-js/enc-base64";
import { useState } from "react";

import { isError } from "Common/TypeGuards";
import { Article } from "Components/Article";
import { UrlInput } from "Components/UrlInput";
import { IKeyValueData, KeyValue, KeyValueList } from "Tools/Components/KeyValueList";

const dataUriMatcher = /data:([^,]*?)?(;base64)?,(.*)/;
const linkMatcher = /^https?:\/\/.*$/;

function buildAnchor(url: string) {
	const anchor = document.createElement("a");
	anchor.href = url;

	return anchor;
}

function clickableLinks(keyValue: KeyValue): KeyValue {
	const value = keyValue[2];
	const hasNode = typeof value === "object" && value?.node != null;
	const textValue = typeof value === "string" ? value : value?.text;

	if (!hasNode && textValue && linkMatcher.test(textValue)) {
		return [
			keyValue[0],
			keyValue[1],
			{ node: <a key={keyValue[0]} href={textValue} target="_blank" rel="noopener noreferrer">{textValue}<span className="bi bi-box-arrow-up-right ms-2"></span></a>, text: textValue }
		];
	}

	return keyValue;
}

function splitKeyValueString(item: string): KeyValue {
	const parts = item.split("=");
	const key = parts[0];
	const value = parts.length === 2 ? parts[1] : parts.slice(1).join("=");

	try {
		return [key, key, decodeURIComponent(value)];
	} catch {
		return [key, key, value];
	}
}

export class UrlStringParser {
	private readonly anchor: HTMLAnchorElement;

	public constructor(private readonly url: string) {
		this.anchor = buildAnchor(url);
	}

	public getBaseValues(): KeyValue[] {
		try {
			const parts: KeyValue[] = [
				["protocol", "Protocol", this.anchor.protocol],
				["hostname", "Hostname", this.anchor.hostname],
				["port", "Port", this.anchor.port],
				["path", "Path", this.anchor.protocol !== "data:" ? this.anchor.pathname : null]
			];

			return parts.filter((item: KeyValue) => item[2] != null);
		} catch {
			return [];
		}
	}

	public getQueryValues(): KeyValue[] {
		if (!this.anchor.search) {
			return [];
		}

		const params = this.anchor.search.substr(1).split("&");

		return params
			.map(splitKeyValueString)
			.map(clickableLinks);
	}

	public getHashValues(): KeyValue[] {
		if (!this.anchor.hash) {
			return [];
		}

		if (/^#([\w\d_]+=[^&]+)(&[\w\d_]+=[^&]+)*$/.test(this.anchor.hash)) {
			const params = this.anchor.hash.substr(1).split("&");

			return params
				.map(splitKeyValueString)
				.map(clickableLinks);
		}

		return [["hash", "Hash", this.anchor.hash.substr(1)]];
	}

	// data:[<mediatype>][;base64],<data>
	// https://tools.ietf.org/html/rfc2397
	public getDataContent(): KeyValue[] {
		if (this.anchor.protocol !== "data:") {
			return [];
		}

		const match: (string | undefined)[] | null = dataUriMatcher.exec(this.url);
		const results: KeyValue[] = [];

		if (match == null) {
			results.push([
				"error",
				"Error",
				"Could not parse."
			]);

			return results;
		}

		const mimeType = match[1];
		if (mimeType != null) {
			results.push(["mimeType", "MIME type", mimeType]);
		}

		if (mimeType?.startsWith("image/") === true) {
			results.push([
				"image",
				"Image",
				{ node: <img key={this.url} src={this.url} />, text: this.url }
			]);
		} else {
			const isBase64 = match[2] != null;
			const data = match[3];

			try {
				const convertedData = isBase64 && data != null
					? base64.parse(data).toString()
					: decodeURIComponent(data ?? "");

				results.push([
					"data",
					"Data",
					{
						node: (
							<textarea
								className="form-control"
								disabled
								key="data"
								rows={10}
								value={convertedData}
							/>
						),
						text: convertedData
					}
				]);
			} catch (error: unknown) {
				results.push([
					"error",
					"Error",
					isError(error) ? error.toString() : "Could not parse."
				]);
			}
		}

		return results;
	}

	public getAsMarkdown() {
		function asMarkdown(data: IKeyValueData | string | null) {
			const text = typeof data === "string" ? data : data?.text ?? "";
			const escaped = text.replace(/\|/g, "\\|");

			if (linkMatcher.test(text)) {
				return `[${escaped}](${escaped})`;
			}

			if (dataUriMatcher.test(text)) {
				return `![](${escaped})`;
			}

			return "`" + escaped + "`";
		}

		function getTable(values: KeyValue[], title: string, column1: string, column2: string) {
			if (values.length === 0) {
				return null;
			}

			return `**${title}**\n` +
				"\n" +
				`| ${column1} | ${column2} |\n` +
				"| - | - |\n" +
				values.map(x => `| ${asMarkdown(x[1])} | ${asMarkdown(x[2])} |`).join("\n") + "\n";
		}

		const documentParts = [
			getTable(this.getBaseValues(), "Base URL", "URL Component", "Value"),
			getTable(this.getQueryValues(), "Query string", "Parameter", "Value"),
			getTable(this.getHashValues(), "Hash values", "Parameter", "Value"),
			getTable(this.getDataContent(), "Data content", "Parameter", "Value")
		];

		return documentParts.filter(x => x != null).join("\n");
	}
}

export function UrlParser() {
	const [url, setUrl] = useState("");
	const parser = new UrlStringParser(url);
	const baseValues = parser.getBaseValues();
	const queryValues = parser.getQueryValues();
	const hashValues = parser.getHashValues();
	const dataContent = parser.getDataContent();

	return (
		<article>
			<Article.Header>
				<Article.Headline>URL parser</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted btn-tinted-primary" type="button" onClick={() => { void navigator.clipboard.writeText(parser.getAsMarkdown()); }}>
						<span className="bi bi-clipboard me-2"></span> Copy as Markdown
					</button>
					<button className="btn btn-tinted" onClick={() => setUrl("")}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<div className="mb-4">
				<UrlInput
					id="urlparser-input"
					label="URL"
					value={url}
					placeholder="https://example.com/path?param=value"
					onChange={value => setUrl(value)}
				/>
			</div>
			{url.length > 0 &&
				<div>
					<h3>Base URL</h3>
					<KeyValueList keyValues={baseValues} />
				</div>
			}
			{queryValues.length > 0 &&
				<div className="mt-4">
					<h3>Query string</h3>
					<KeyValueList keyValues={queryValues} />
				</div>
			}
			{hashValues.length > 0 &&
				<div className="mt-4">
					<h3>Hash value</h3>
					<KeyValueList keyValues={hashValues} />
				</div>
			}
			{dataContent.length > 0 && (
				<div className="mt-4">
					<h3>Data content</h3>
					<KeyValueList keyValues={dataContent} />
				</div>
			)}
		</article>
	);
}
